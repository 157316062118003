@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* These styles will be purged */
@layer components {
  .overflow-hidden-important {
    overflow: hidden !important;
  }
  .admin-form-default {
    @apply relative flex flex-grow flex-row mt-6 overflow-y-hidden;
  }
  .help-component-default {
    @apply absolute right-0 top-0;
  }
}

@layer base {
  .homePage ol {
    @apply list-inside list-decimal !important;
  }
  .homePage ul {
    @apply list-inside list-disc !important;
  }
}

/* The styles here (outside the `@layer`) will not be purged */
